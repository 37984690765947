/* Styles for QuestList component */
.questlist-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.questlist-header {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

.questlist {
    list-style-type: none;
    padding: 0;
}

.questlist-item {
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.questlist-item-header {
    font-size: 18px;
    color: #107980;
    margin-bottom: 10px;
}

.questlist-item-description {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
}

.questlist-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.questlist-button:hover {
    background-color: #76A1A7;
}
