/* Custom styles for menudashboardbtaccueil component */
.menudashboard-navbar {
    width: 100%;
    background-color: #107980;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.menudashboard-menu {
    list-style-type: none;
    padding: 0;
    display: flex;
}

.menudashboard-button {
    color: white;
    background-color: #76A1A7;
    margin: 0.5em;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
    font-weight: bold;
}

.menudashboard-button:hover {
    background-color: #107980;
    transform: scale(1.05);
    color: white;
}