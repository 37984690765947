/* Custom styles for objectivecreateform component */
.objectivecreateform-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivecreateform-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.objectivecreateform-label {
    font-size: 16px;
    font-weight: bold;
}

.objectivecreateform-input,
.objectivecreateform-select,
.objectivecreateform-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.ObjTableItems-tr th {
    background-color: #107980;
    text-align: center;
}

.objectivesCreateForm-body .objectivesCreateForm-row:nth-child(even) td {
    background-color: #e5eef0;
}

.objectivecreateform-InputSearch {
    margin-bottom: 20px;
}

.objectivecreateform-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.objectivecreateform-btn {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 16px;
}

.objectivecreateform-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.ObjTableItems .td-type-1 {
    text-align: center;
}