/* Footer.css */
.footer {
    background-color: #107980;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    z-index: 1000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.nav-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
}

.menu {
    position: absolute;
    bottom: 70px;
    background-color: #ffffff;
    color: #107980;
    width: 50%;
    left: 0;
    text-align: center;
    border-radius: 8px;
    font-weight: bold;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    padding: 10px;
    cursor: pointer;
}

.menu li:hover {
    background-color: #EDE1CF;
    border-radius: 8px;
}

/* Tooltip */
.nav-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.nav-button::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
    font-size: 80%;
}

.nav-button::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.nav-button:hover::before,
.nav-button:hover::after {
    opacity: 1;
    visibility: visible;
}