/* Custom styles for userupdate component */
.userupdate-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userupdate-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.userupdate-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.userupdate-form-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.userupdate-line {
    flex: 1;
    min-width: 300px;
}

.userupdate-read-only input {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.userupdate-line p {
    margin-bottom: 5px;
    color: #333;
}

.userupdate-line input {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.userupdate-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.userupdate-buttons button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.userupdate-buttons button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
