/* Custom styles for itemfetchall component */
.itemfetchall-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.itemfetchall-table {
    width: 100%;
    border-collapse: collapse;
}

.itemfetchall-table th, .itemfetchall-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.itemfetchall-table th {
    background-color: #107980;
    color: white;
}

.itemfetchall-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.itemfetchall-table tbody tr:hover {
    background-color: #f9f9f9;
}

.itemfetchall-btn {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.itemfetchall-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
