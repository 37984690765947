/* Custom styles for itemcreate component */
.itemcreate-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.itemcreate-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Form input styles */
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

input[type="submit"],
.submit-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

input[type="submit"]:hover,
.submit-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
