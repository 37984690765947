/* Custom styles for userLinkToObjective component */
.objectivegetone-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetonebyuser-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
}

.objectivegetone-card {
    width: 70%;
    margin: auto;
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetonebyuser-card-text {
    margin-left: 15%;
    color: #333;
}

.objectivegetone-details-section {
    color: #333;
    margin: 30px 0 10px 0;
    text-align: left;
    font-size: 20px;
    text-decoration: underline;
}

.userObjectivesDetails li {
    margin-left: 5%;
    text-align: left;
    list-style-type: square;
    margin-bottom: -10px;
}

.userObjectivegetone-title {
    color: #107980;
    font-size: 20px;
    font-weight: bold;
}

.objectiveSearchLabel {
    margin-bottom: 30px;
}

.objectivesSave-btn {
    display: block;
    background-color: #107980;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 17px;
    margin: auto;
}

.objectivesSave-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.userLinkNav {
    display: flex;
    justify-content: space-evenly;
    margin: 30px;
}

.objectivesNav1-btn {
    background-color: #107980;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 16px;
    margin-left: 150px;
}

.objectivesNav2-btn {
    background-color: #107980;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 16px;
}

.objectivesNav1-btn:hover,
.objectivesNav2-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.toggleExpand {
    margin-left: 8px;
    background-color: #f5e8d5ee;
    border-radius: 5px;
    font-size: 12.5px;
}

.toggleExpand:hover {
    background-color: #c7b6af;
}

.noAnswer {
    padding-bottom: 20px;
    ;
}

.sortSelect,
.sortSelect label,
.sortSelect input {
    padding: 2px;
}

.sortSelect,
.sortSelect input {
    margin-bottom: 2px;
}