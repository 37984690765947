/* Custom styles for teamscreation component */
.teamscreation-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.teamscreation-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.teamscreation-container .teamsform-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}