/* Styles for QuestPanel component */
.quest-panel-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.quest-panel-header {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

.quest-panel-navbar {
    background-color: #B6ADAF;
    padding: 10px;
    border-radius: 5px;
}

.quest-panel-nav-links {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
}

.quest-panel-nav-link {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.quest-panel-nav-link:hover {
    background-color: #76A1A7;
}

.quest-panel-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quest-panel-button:hover {
    background-color: #76A1A7;
}

.quest-panel-footer {
    background-color: #107980;
    color: white;
    text-align: center;
    padding: 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 5px;
}

.quest-panel-footer p {
    margin: 0;
}
