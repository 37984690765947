/* Header.css */
.header {
    background-color: #107980;
    color: white;
    text-align: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;    
}

h1 {
    margin: 0;
    font-size: 40px;
    color: #EDE1CF;
    font-weight: bold;
}