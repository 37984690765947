/* Styles for QuestById component */
.quest-by-id-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.quest-by-id-title {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

.quest-by-id-form .form-group {
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
}

.quest-by-id-form input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.quest-by-id-form .button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quest-by-id-form .button:hover {
    background-color: #76A1A7;
}

.quest-details {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.quest-details h3 {
    color: #107980;
    margin-bottom: 10px;
}

.quest-details p {
    margin: 5px 0;
    font-size: 16px;
}

.quest-details strong {
    color: #107980;
}
