/* Custom styles for userfetchbyid component */
.userfetchbyid-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userfetchbyid-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.userfetchbyid-card {
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.userfetchbyid-card-content {
    display: flex;
    justify-content: space-between;
}

.userfetchbyid-info-left p,
.userfetchbyid-info-right p {
    margin: 5px 0;
    color: #333;
    min-height: 26px; /* Pour éviter les cases vide écraser */
}

.userfetchbyid-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.userfetchbyid-buttons button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.userfetchbyid-buttons button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
