body{
    /*font-family: Pixel, sans-serif;*/
    font-size: 15pt;
}

.Container-Title{
    padding-left: 1rem;
}

.Store-Container{
    display: grid;
    grid-template-columns:0.25fr 2fr;

}

.Side-Menu{
    padding: 1rem;
    white-space: nowrap;
}

.Side-Menu ul{
    list-style-type: none;
}

.Side-Menu li {
    width: 10rem;
}

.Side-Menu li:hover{
    /*background-color: #107980;*/
    background-color: #EDE1CF;
}

.Main-Content{
    padding-inline: 2rem;
}

.Store-Search{
    display: grid;
    grid-template-columns:1fr 1fr;
    column-gap: 5rem;
    margin-bottom: 2vh;
}


.Items-Grid{
    background: #EDE1CF;
    border-radius: 15px;
    padding: 2em;
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    justify-items: center;
    gap : 2em;
}

.Item-Card{
    min-width: 10rem;
    max-height: 12rem;
    border: 2px solid lightgray;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
}

.Item-Name{
    font-weight: bold;
}


.Pagination {
    margin-top: 2vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

@media (orientation: portrait) {


    @media (max-width: 1000px) {
        body{
            /*font-family: Pixel, sans-serif;*/
            font-size: 2vw;
            width: 100vw;
        }

        .header{
            width: 100vw;
        }

        .Container-Title{
            padding-left: 5vw;
            font-size: 3vw;
        }

        .Store-Container{
            display: grid;
            grid-template-columns:25vw 75vw;
        }

        .Side-Menu{
            padding: 1vw;
        }

        .Side-Menu ul{
            list-style-type: none;
            padding-left: 0;
        }

        .Side-Menu li {
            width: 100%;
        }

        .Side-Menu li:hover{
            /*background-color: #107980;*/
            background-color: #EDE1CF;
        }

        .Main-Content{
            padding-inline: 1vw;
        }

        .Store-Search{
            display: grid;
            grid-template-columns:50% 50%;
            column-gap: 0;
        }

        .Store-Search select{
            min-width: 0;
            padding: 0;
            max-width: fit-content;
            font-size: 1em;
        }

        .Store-Search input{
            width: 50%;
        }


        .Items-Grid{
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-items: center;
            row-gap : 2rem;
        }

        .Item-Card{
            min-width: 10rem;
            max-height: 12rem;
            border: 2px solid lightgray;
            border-radius: .25rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 1rem;
        }

        .Item-Name{
            font-weight: bold;
        }

        .Pagination {
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;
        }
    }

    @media (max-width: 500px) {
        body{
            /*font-family: Pixel, sans-serif;*/
            font-size: 2vw;
            width: 100vw;
        }

        .header{
            width: 100vw;
        }

        .Container-Title{
            padding-left: 5vw;
            font-size: 3vw;
        }

        .Store-Container{
            display: grid;
            grid-template-columns:25vw 75vw;
        }

        .Side-Menu{
            padding: 1vw;
        }

        .Side-Menu ul{
            list-style-type: none;
            padding-left: 0;
        }

        .Side-Menu li {
            width: 100%;
        }

        .Side-Menu li:hover{
            /*background-color: #107980;*/
            background-color: #EDE1CF;
        }

        .Main-Content{
            padding-inline: 1vw;
        }

        .Store-Search{
            display: grid;
            grid-template-columns:50% 50%;
            column-gap: 0;
        }

        .Store-Search select{
            min-width: 0;
            padding: 0;
            max-width: fit-content;
            font-size: 1em;
        }

        .Store-Search input{
            width: 50%;
        }


        .Items-Grid{
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-items: center;
            row-gap : 2rem;
        }

        .Item-Card{
            min-width: 10rem;
            max-height: 12rem;
            border: 2px solid lightgray;
            border-radius: .25rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 1rem;
        }

        .Item-Name{
            font-weight: bold;
        }

        .Pagination {
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;
        }
    }
}

@media (orientation: landscape) {


    @media (max-width: 1000px) {
        body{
            background: lightgray;
        }
    }

    @media (max-width: 500px) {
        body{
            background: red;
        }
    }

}

/*@font-face {*/
/*    font-family: Pixel;*/
/*    src:*/
/*    url('../../test/pixel.ttf');*/

/*}*/
