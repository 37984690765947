/* Custom styles for itemform component */
.itemform-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.itemform-element {
    margin-bottom: 20px;
}

.itemform-element label {
    display: block;
    font-size: 16px;
    color: #107980;
    margin-bottom: 5px;
}

.itemform-element input[type="text"],
.itemform-element input[type="number"],
.itemform-element select,
.itemform-element textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.itemform-element textarea {
    resize: vertical;
}

.itemform-element img {
    margin-top: 10px;
    border-radius: 5px;
    max-width: 50%;
}

.itemform-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.itemform-buttons button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.itemform-buttons button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.itemform-buttons a {
    color: white;
    text-decoration: none;
}
