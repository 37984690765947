/* Styles for CreateQuest component */
.create-quest-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.create-quest-title {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

.create-quest-form .form-group {
    margin-bottom: 15px;
}

.create-quest-form input[type="text"],
.create-quest-form input[type="number"],
.create-quest-form input[type="date"],
.create-quest-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.create-quest-form .button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-quest-form .button:hover {
    background-color: #76A1A7;
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.close {
    cursor: pointer;
    font-size: 20px;
}

.error {
    color: red;
}

.success {
    color: green;
}
