/* Styles for UpdateQuest component */
.update-quest-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.update-quest-title {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

.update-quest-form .form-group {
    margin-bottom: 15px;
}

.update-quest-form input[type="text"],
.update-quest-form input[type="number"],
.update-quest-form input[type="date"],
.update-quest-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.update-quest-form .fetch-quest-button,
.update-quest-form .submit-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.update-quest-form .fetch-quest-button:hover,
.update-quest-form .submit-button:hover {
    background-color: #76A1A7;
}

.update-quest-form textarea {
    resize: vertical;
}

.update-quest-container .form-group {
    display: flex;
    flex-direction: column;
}

.update-quest-container .form-group button {
    align-self: flex-start;
}
