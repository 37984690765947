
.inventory-grid {
    display: grid;
    grid-template-columns: 60% 40%;
}

.inventory-left {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    justify-items: center;
    align-content: space-between;
    background: #EDE1CF;
}

.inventory-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #585454;
    color: #B6ADAF;
}

.inventory-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    height: 150px;
}

.inventory-item:hover {
    box-shadow: 0 0 15px 0 #585454;
    transition: 0.3s ease-in-out;
}

.inventory-item p {
    font-size: 0.8rem;
}

.item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-extra-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-inventory {
    border: none;
    cursor: pointer;
    margin: 0.2em;
    padding: 0.5em;
    border-radius: 5%;
}

.image-item-inventory:hover {
    cursor: pointer;
}

.button-add-items {
    background-color: #337ab7;
    color: #ffffff;
}

.button-add-items:hover {
}

.button-back {
    background-color: #337ab7;
    color: #ffffff;
}

.button-inventory:hover {
    transition: transform 0.3s ease;
    box-shadow: 0 0 1px 1px #107980;
}

.button-details {
    background-color: #337ab7;
    color: #ffffff;
}

.button-delete {
    background-color: #be3737;
    color: #c8bdbd;
}

@media (max-width: 768px) {
    .inventory-grid {
        display: flex;
        flex-direction: column;
    }

    .item-extra-details {
        display: block;
        width: 100%;
    }

    .item-extra-details p {
        width: 100%;
    }

    .item-extra-details img {
        display: inline-block;
    }

    .item-extra-details button {
        display: inline-block;
        width: 50%;
    }
}