.Pagination button {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 17px;
    margin: 10px;
}

.Pagination button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.Pagination {
    text-align: center;
    font-size: 17px;
}