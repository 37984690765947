/* Custom styles for teamsStyles components */
.buttonEdit {
    background-color: purple;
    border: 0.5px solid rgb(180, 4, 180);
    border-radius: 4px;
    color: white;
    margin-right: 5px;
}

.buttonDelete,
.buttonClose {
    background-color: rgb(145, 3, 3);
    border: 0.5px solid red;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
}

.buttonSee {
    background-color: rgb(4, 4, 159);
    border: 0.5px solid blue;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
}

.buttonDelete:hover,
.buttonClose:hover {
    background-color: red;
}

.buttonEdit:hover {
    background-color: rgb(180, 4, 180);
}

.buttonSee:hover {
    background-color: blue;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: black;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.teamsmanagement-team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 16px;
}

.teamsmanagement-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 16px;
}

.team-quests-fetch-container {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 16px;
}

.teamsmanagement-title {
    color: #107980;
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}

.teamsmanagement-team-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    transition: transform 0.2s;
    background: linear-gradient(to bottom, #ffffff, #f0f0f0);
}

.teamsmanagement-team-card:hover {
    transform: scale(1.05);
}

.teamsmanagement-team-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.teamsmanagement-team-card-content {
    padding: 16px;
    text-align: left;
}

.teamsmanagement-team-card img {
    width: 100%;
    height: auto;
}

.teamsmanagement-team-card-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    display: block;
}

.teamsmanagement-team-card h2 {
    margin: 0 0 8px;
    font-size: 1.5em;
}

.teamsmanagement-team-card p {
    margin: 4px 0;
}


.center {
    text-align: center;
}

.strong {
    font-weight: bold;
    font-size: 120%;
}

.teamstyles-email {
    text-decoration: underline;
    color: #107980 !important;
}

.teamstyles-search-and-sort-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

.teamstyles-search-bar {
    width: 300px;
    padding: 10px;
    margin: 20px 0;
    border: 2px solid #ccc;
    background-color: #ccc;
    border-radius: 8px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
}

.teamstyles-search-bar:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.teamstyles-sort-buttons {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.teamstyles-sort-buttons button {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #107980;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 9em;
}

.teamstyles-sort-buttons button:hover {
    background-color: #B6ADAF;
}

.teamstyles-sort-buttons button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.teamstyles-listStyle {
    text-align: left;
    margin-left: 10%;
    font-size: 110%;
}

.teamstyles-listStyle li {
    margin: 3%;
}

.teamstyles-grey {
    color: grey;
    font-style: normal;
}

/* Team quests */
.teamsform-container.large {
    width: 97%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.teamsform-container.noflex {
    width: 83%;
    display: block;
}

.team-quests-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.team-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.assign-quest-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.assign-quest-button:hover {
    background-color: #0056b3;
}

.quests-list {
    list-style-type: none;
    padding: 0;
}

.quest-item {
    display: flex;
    align-items: center;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
}

.quest-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.quest-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    display: block;
    margin-right: 10px;
}

.quest-details {
    flex-grow: 1;
    text-align: left;
    margin: 2%;
}

.quest-difficulty-level {
    color: #6c757d;
    font-size: 14px;
    font-style: italic;
}

.quest-description {
    margin-top: 5px;
    color: black;
    font-weight: bold;
}

.quest-title {
    margin-top: 5px;
    color: #107980;
    font-weight: bold;
    font-variant: small-caps;
    font-size: 120%;
}

.teamQuestLabel {
    color: black;
}

.buttonSee.addTeamQuest {
    width: auto;
    margin-top: 3%;
}

.ReactModal__Content.ReactModal__Content--after-open.modal {
    padding: 2% 3% 2% 3% !important;
    display: block;
    width: auto;
    height: auto;
}

.modal img {
    width: 5svw;
    height: 13svh;
    object-fit: cover;
    object-position: center;
    display: block;
    margin: 2% auto;
    border-radius: 50%;
}

.center {
    text-align: center;
}

.center.buttons {
    margin-top: 20%;
}

.buttonSee,
.buttonClose {
    margin: 5px;
}

.teamsmanagement-modal-name {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
    color: #107980;
}

/* Team users */
.team-logo-team-users {
    width: 100px;
}

.team-logo-team-users img {
    border-radius: 8px;
}

/* Tableau all team quests */
.quests-table {
    width: 100%;
    margin: 0 0 5%;
    position: relative;
    border: none;
    border-collapse: separate;
    border-spacing: 5px;
}

.teamFirst {
    background-color: #107980;
    color: white;
    font-weight: bold;
}

.teamSecond {
    background-color: #76A1A7;
    font-size: 90% !important;
    font-weight: 700;
}

.questFirst {
    background-color: #B6ADAF;
    color: white;
    font-weight: bold;
}

.questSecond {
    background-color: #D9C5BC;
    font-size: 90% !important;
    font-weight: 700;
}

.quests-table th,
.quests-table td {
    border: none;
    border-radius: 8px;
    padding: 12px;
    text-align: center;
}

.quests-table th {
    font-size: 1.5em;
    position: sticky;
    top: 0;
    z-index: 10;
}

.quests-table td {
    font-size: 0.9em;
    color: black;
    transition: background-color 0.3s;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.quests-table tbody tr:nth-child(even) {
    background-color: #EDE1CF;
}

.quests-table td:hover {
    background-color: #e0e0e0;
}

.quests-table tr:hover td {
    background-color: #B6ADAF;
    color: white !important;
}

.quests-table th:nth-child(1),
.quests-table td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 5;
}

.quests-table img {
    width: 50px;
    height: auto;
    border-radius: 8px;
}

.quests-table td img {
    display: block;
    margin: 0 auto;
}

.quest-state:hover {
    color: #107980;
    font-weight: bold;
}

.textAlignLeft {
    text-align: left !important;
    padding-left: 2% !important;
}

/* Jauge */
.difficulty-gauge-container {
    display: flex;
    gap: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
}

.gauge-segment {
    width: 16px;
    height: 10px;
    border-radius: 8px;
}

.cell-center {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Tooltip */
.buttonSee.tooltip,
.buttonEdit.tooltip,
.buttonClose.tooltip,
.buttonDelete.tooltip,
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.buttonSee.tooltip::before,
.buttonEdit.tooltip::before,
.buttonClose.tooltip::before,
.buttonDelete.tooltip::before,
.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.buttonSee.tooltip::after,
.buttonEdit.tooltip::after,
.buttonClose.tooltip::after,
.buttonDelete.tooltip::after,
.tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.buttonSee.tooltip:hover::before,
.buttonSee.tooltip:hover::after,
.buttonEdit.tooltip:hover::before,
.buttonEdit.tooltip:hover::after,
.buttonClose.tooltip:hover::before,
.buttonClose.tooltip:hover::after,
.buttonDelete.tooltip:hover::before,
.buttonDelete.tooltip:hover::after,
.tooltip:hover::before,
.tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}

.quest-state {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.quest-state::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.quest-state::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.quest-state:hover::before,
.quest-state:hover::after {
    opacity: 1;
    visibility: visible;
}

/* Fin Teams _________________________________________________________________*/