/* Custom styles for objectivegetone component */
.objectivegetone-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetone-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
}

.objectivegetone-card {
    width: 70%;
    margin: auto;
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetone-card-text {
    margin-left: 15%;
    color: #333;
}

.objectivegetone-details-section {
    color: #333;
    margin: 30px 0 10px 0;
    text-align: left;
    font-size: 20px;
    text-decoration: underline;
}

.objectivegetone-details {
    margin-bottom: 10px;
    color: #333;
}

.itemDetails .objectivegetone-details,
.itemDetails .objectivegetone-details-items {
    margin-bottom: 35px;
    list-style-type: square;
}

.itemDetails .objectivegetone-details-items p {
    margin-bottom: -15px;
}

.objectivegetone-details-items .objective-user {
    font-size: 17px;
    font-weight: bold;
    color: #107980;
}

.objectivegetone-actions {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin-top: 80px;
}

.objectivegetone-btn-action {
    background-color: #107980;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 16px;
}

.objectivegetone-btn-action:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}