/* Custom styles for objectivegetone component */
.objectivegetone-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetonebyuser-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
}

.objectivegetone-card {
    width: 70%;
    margin: auto;
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivegetonebyuser-card-text {
    margin-left: 15%;
    color: #333;
}

.objectivegetone-details-section {
    color: #333;
    margin: 30px 0 10px 0;
    text-align: left;
    font-size: 20px;
    text-decoration: underline;
}

.userObjectivesDetails li {
    margin-left: 5%;
    text-align: left;
    list-style-type: square;
    margin-bottom: -10px;
}

.userObjectivegetone-title {
    color: #107980;
    font-size: 20px;
    font-weight: bold;
}

.objectivesgetall-sort-btn {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin-top: 80px;
}

.objectivesgetall-sort-btn {
    background-color: #107980;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 17px;
}

.objectivesgetall-sort-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}