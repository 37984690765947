/* Style général du body */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #F5F5F5;
  color: #333;
  line-height: 1.6;
}

/* Liens (hors navbar) */
a {
  color: #107980;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #76A1A7;
}

/* Paragraphes */
p {
  margin: 0 0 15px 0;
  font-size: 16px;
}

/* Listes */
ul,
ol {
  margin: 15px 0;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  border: 1px solid #D9C5BC;
  text-align: left;
}

th {
  background-color: #EDE1CF;
  color: #333;
}

td {
  background-color: #FFF;
}

/* Champs de formulaires généraux */
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  box-sizing: border-box;
  font-size: 14px;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Conteneur de base */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Titres */
h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  font-size: 2em;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.75em;
  margin-bottom: 10px;
}

/* Homepage User */
.content {
  padding-top: 100px;
  padding-bottom: 100px;
}