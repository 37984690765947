/* Custom styles for userfetchall component */
.userfetchall-container {
    width: 100%;
    background-color: #EDE1CF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
}

.userfetchall-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.userfetchall-search {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.userfetchall-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.userfetchall-table th, .userfetchall-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #D9C5BC;
}

.userfetchall-row:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.userfetchall-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.userfetchall-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
