/* Custom styles for menudashboardbtsections component */
.menudashboard-sections-container {
    width: 100%;
    background-color: #107980;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menudashboard-sections-button {
    color: white;
    background-color: #76A1A7;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.menudashboard-sections-button:hover {
    background-color: #107980;
    transform: scale(1.05);
    color: white;
}