/* Custom styles for inscriptionform component */
.inscriptionform-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.inscriptionform-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.inscriptionform-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inscriptionform-input,
.inscriptionform-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.inscriptionform-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.inscriptionform-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
