/* Custom styles for userquestlink component */
.userquestlink-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userquestlink-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.userquestlink-card {
    background-color: white;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userquestlink-subtitle {
    color: #107980;
    margin-bottom: 10px;
}

.userquestlink-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.userquestlink-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.userquestlink-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}
