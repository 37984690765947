/* Custom styles for objectivesSortByUsers component */
.objectivesSortByUsers-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivesSortByUsers-title {
    color: #107980;
    text-align: center;
    font-size: 26px;
    margin-bottom: 20px;
}

.totalUserObjectives {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.nbTotal {
    font-size: 20px;
    font-weight: bold;
    color: #107980;
}

#ObjectiveExplain {
    font-size: 16px;
    text-align: center;
}

.sort-objectives-with-users {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px;
}

.objectivesSortByUsers-btn {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    display: block;
    margin: 20px 0;
    font-size: 16px;
}

.objectivesSortByUsers-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesgetall-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.objectivesgetall-header th {
    background-color: #107980;
    color: white;
    text-align: center;
    padding: 10px;
}

.objectivesByUsers-body .objectivesByUsers-row:nth-child(even) td {
    background-color: #e5eef0;
}

.objectivesgetall-link {
    color: #107980;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
}

.bold {
    font-weight: bold;
    font-size: 17px;
}

.objectivesgetall-link:hover {
    color: #76A1A7;
}

.objectives-Users-LinkBtn {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 14px;
}

.objectives-Users-LinkBtn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesgetall-desc-2 .NoAnswer {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
}

.objectivesgetall-desc-2 p {
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: -15px;
}

.objectivesgetall-desc-2 li {
    list-style-type: square;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
}

.objectivesgetall-desc-2 li .obj-bold {
    font-weight: bold;
    font-size: 18px;
}

.underline {
    text-decoration: underline;
}

.objectivesPagination button {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 17px;
    margin: 10px;
}

.objectivesPagination button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesPagination {
    text-align: center;
    font-size: 17px;
}

#currentPage {
    font-size: 18px;
    font-weight: bold;
}