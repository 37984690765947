/* Custom styles for menusectionusers component */
.menusectionusers-navbar {
    width: 100%;
    background-color: #107980;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.menusectionusers-menu {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.menusectionusers-button {
    color: white;
    background-color: #76A1A7;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.menusectionusers-button:hover {
    background-color: #107980;
    transform: scale(1.05);
}

