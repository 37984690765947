/* Conteneur principal pour le formulaire UserQuest */
.userquest-form-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Titre du formulaire */
.userquest-form-title {
    font-size: 24px;
    text-align: center;
    color: #107980;
    margin-bottom: 20px;
}

/* Style du formulaire */
.userquest-form .form-group {
    margin-bottom: 15px;
}

/* Style des champs de sélection */
.userquest-form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    background-color: white;
    color: #333;
    font-size: 16px;
}

/* Bouton de soumission */
.userquest-form .button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userquest-form .button:hover {
    background-color: #76A1A7;
}

/* Message d'erreur */
.userquest-form .error-message {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}

/* Message de succès */
.userquest-form .success-message {
    color: green;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}