/* Custom styles for objectivesgetall component */
.objectivesgetall-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.objectivesgetall-title {
    color: #107980;
    text-align: center;
    font-size: 26px;
    margin-bottom: 20px;
}

.totalObjectives {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.nbTotal{
    font-size: 20px;
    font-weight: bold;
    color: #107980;
}

#ObjectiveExplain {
    font-size: 16px;
    text-align: center;
}

.ObjectiveGetAll-Nav-btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 80px 45px 90px;
}

.objectivesgetall-sort-btn {
    display: block;
    background-color: #107980;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 16px;
}

.objectivesgetall-sort-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesgetall-btn {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    display: block;
    margin: 20px 0;
}

.objectivesgetall-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.objectivesgetall-header th {
    background-color: #107980;
    color: white;
    text-align: center;
    padding: 10px;
}

.objectivesgetall-body tr:nth-child(even) {
    background-color: #f2f2f2;
}

.objectivesgetall-link {
    color: #107980;
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: bold;
    text-align: left;
}

.objectivesgetall-link:hover {
    color: #76A1A7;
}

.objectivesgetall-desc {
    text-align: center;
    font-size: 17px;
    padding: 20px;
}

.objectivesgetall-desc-title .objectivesgetall-link {
    text-align: left;
    font-size: 18px;
}

.ObjectiveUserListName .objectivesgetall-link {
    font-size: 15px;
    color: #107980;
}

.objectivesgetall-desc-1 {
    text-align: center;
    font-size: 16px;
}

.objectivesgetall-desc-2 {
    text-align: left;
    font-size: 16px;
}

.objectivesgetall-desc-3 {
    text-align: left;
    font-size: 16px;
}

.objectivesgetall-desc-3 .NoAnswer {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
}

.objectivesgetall-desc-3 ul {
    list-style-type: square;
}

.objectivesgetall-desc-3 p {
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: -15px;
}

.objectivesgetall-desc-3 li {
    list-style-type: square;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
}

.objectivesgetall-desc-3 .ObjectiveUserListName {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: -15px;
}

.objectivesgetall-btn-action {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 15px;
}

.objectivesgetall-btn-action:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesgetall-body .objectivesgetall-row:nth-child(even) td {
    background-color: #e5eef0;
}

.objectivesPagination button {
    background-color: #107980;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-size: 17px;
    margin: 10px;
}

.objectivesPagination button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.objectivesPagination {
    text-align: center;
    font-size: 17px;
}

#currentPage {
    font-size: 18px;
    font-weight: bold;
}