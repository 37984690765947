/* Custom styles for teamsform component */
.teamsform-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.teamsform-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.teamsform-label {
    display: block;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
    color: grey;
    font-weight: bold;
}

.teamsform-preview {
    display: none;
    width: 100px;
    height: auto;
    margin-top: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.teamsform-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    width: 100%;
    margin-top: 20px;
}

.teamsform-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}