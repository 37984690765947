/* homepageUser.css */
.homepage-user {
    text-align: center;
    padding: 20px;
}

.user-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.quest-list {
    list-style-type: none;
    padding: 0;
}

.grey {
    color: #B6ADAF;
}

.quest-item {
    background-color: #EDE1CF;
    margin: 10px 0;
    padding: 15px;
    border-radius: 8px;
    color: #107980;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.quest-item h3 {
    margin: 0;
}

.quest-item p {
    margin: 5px 0;
}

.homepage-title {
    color: #107980;
    text-align: left;
    font-size: 2em;
    margin-bottom: 20px;
}

.homepage-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    font-weight: bold;
}

.homepage-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.user-info {
    text-align: left;
    color: #B6ADAF;
    margin: 0;
}

/* Progress bar quêtes */
.progress-bar-container {
    background-color: #B6ADAF;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 20px;
    margin-top: 8px;
    cursor: pointer;
    color: #EDE1CF;
    font-size: 80%;
    font-weight: bold;
}

.progress-bar {
    background-color: #107980;
    height: 100%;
    transition: width 0.3s ease-in-out;
    border-radius: 8px;
}

/* Tooltip */
.progress-bar-container.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.progress-bar-container.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
    font-size: 80%;
}

.progress-bar-container.tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 9999;
}

.progress-bar-container.tooltip:hover::before,
.progress-bar-container.tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}