/* Custom styles for homepage component */
.homepage-container {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 16px;
}

.homepage-title {
    color: #107980;
    text-align: center;
    font-size: 80px;
    margin-bottom: 20px;
}

.homepage-h2 {
    color: #107980;
    text-align: center;
    font-size: 30px;
}

.homepage-h2.margin-top {
    margin-top: 85px;
}

.homepageform-container {
    width: 80%;
    height: auto;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.homepageform-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
    box-sizing: border-box;
}

.homepageform-label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.homepageform-preview {
    display: none;
    width: 100px;
    height: auto;
    margin-top: 10px;
    border: 1px solid #D9C5BC;
    border-radius: 5px;
}

.homepageform-button {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    width: 100%;
    margin-top: 20px;
}

.homepageform-button:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.password-input-container {
    display: flex;
    align-items: center;
}

.toggle-password-visibility {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: #107980;
    font-weight: bold;
    font-size: 110%;
}