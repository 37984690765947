/* components/public/Loading/LoadingBar.css */
.loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(8, 16, 18, 0.5);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    color: white;

}

.loading-bar {
    width: 40%;
    background-color: #EDE1CF;
    border: 2px solid rgba(8, 16, 18, 0.5);
    border-radius: 8px;
    position: relative;
    height: 2em;
    margin-top: 0.8em;
}

.loading-bar-progress {
    height: 100%;
    width: 0;
    background-color: #107980;
    animation: load 1s ease-in-out;

}

/* animation de la div 'loading-bar-progress'  */
@keyframes load {
    0% {
        width: 0;
    }

    100% {
        width: 100%;

    }

}

p {
    margin-top: 0.8em;
    font-size: 1em;
    font-weight: bold;
}