/* Custom styles for itemindex component */
.itemindex-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #EDE1CF;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.itemindex-title {
    color: #107980;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.itemindex-btn {
    background-color: #107980;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    margin-bottom: 20px;
}

.itemindex-btn:hover {
    background-color: #76A1A7;
    transform: scale(1.05);
}

.itemindex-link {
    color: white;
    text-decoration: none;
}
